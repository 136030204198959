import { type Account } from './Account';
import { type Fund } from './Fund';

export type ColumnTitle = {
  subtitle?: string,
  title: string,
};

export type ColumnTitlesAndLabels = Record<ColumnsToDisplayEnum, ColumnTitle>;

export enum ColumnsToDisplayEnum {
  commitment = 'commitment',
  contribution = 'contribution',
  distribution = 'distribution',
  investor = 'investor',
  nav = 'nav',
  other = 'other',
  realizedGainLoss = 'realizedGainLoss',
  reportingPeriod = 'reportingPeriod',
  unrealizedGainLoss = 'unrealizedGainLoss'
}

export const ColumnsToDisplay: ColumnTitlesAndLabels = {
  commitment: {
    title: 'Commitment',
  },
  contribution: {
    subtitle: '(Net due/advance)',
    title: 'Contribution',
  },
  distribution: {
    title: 'Distribution',
  },
  investor: {
    title: 'Investor',
  },
  nav: {
    title: 'Partners capital \n(NAV)',
  },
  other: {
    title: 'Other',
  },
  realizedGainLoss: {
    title: 'Real. portf. \ngain/(loss)',
  },
  reportingPeriod: {
    title: 'Reporting period',
  },
  unrealizedGainLoss: {
    title: 'Unreal. portf. \ngain/(loss)',
  },
};

export const SecondTableColumnKeys: ColumnsToDisplayEnum[] = [
  ColumnsToDisplayEnum.contribution,
  ColumnsToDisplayEnum.distribution,
  ColumnsToDisplayEnum.unrealizedGainLoss,
  ColumnsToDisplayEnum.realizedGainLoss,
];

export const SecondTableColumns = SecondTableColumnKeys.map((entry) => {
  const title = ColumnsToDisplay[entry];
  return [
    entry.toString(),
    title,
  ] as [string, ColumnTitle];
});

export type FixedColumns = {
  commitment: number,
  contribution: number,
  distribution: number,
  realizedGainLoss: number,
  unrealizedGainLoss: number,
};

export type AggregatedCustomColumns = {
  other: number,
};

export type NavColumn = {
  nav: number,
};

export type TableColumnDisplayValues = AggregatedCustomColumns &
FixedColumns & NavColumn & { legalName: string, };

export type PeriodComputedValuesRecord = Record<string, TableColumnDisplayValues>;

export type CapitalAccountReportCustomColumn = {
  key: string,
  label: string,
};

export type CapitalAccountReportPeriodMetaInfo = {
  _id: string,
  customColumns: CapitalAccountReportCustomColumn[],
  lastUploadedAt: string,
  reportingPeriod: string,
};

export type CapitalAccountPeriodDataColumns = FixedColumns & Record<string, number>;

export type CapitalAccountPeriodInformation = {
  _id: string,
  capitalAccountReport: CapitalAccountReportPeriodMetaInfo,
  data: CapitalAccountPeriodDataColumns,
};

export type CapitalAccountPeriodRecord = Record<string, CapitalAccountPeriodInformation>;

export type CapitalAccountAccount = Pick<Account, '_id' | 'legalName'> & {
  lastUploadedAt: string,
  periods: CapitalAccountPeriodRecord,
};

export type CapitalAccountFund = Pick<Fund, '_id' | 'entityNumber' | 'legalName'> & {
  accounts: CapitalAccountAccount[],
  lastUploadedAt: string,
};

export type ComputedCapitalAccountInfoAccount = Pick<Account, '_id' | 'legalName'> & {
  periods: PeriodComputedValuesRecord,
};

export type ComputedCapitalAccountFund = Pick<Fund, '_id' | 'legalName'> & {
  accounts: ComputedCapitalAccountInfoAccount[],
};

export type SelectedFundAccountPeriodMap = Record<string, Record<string, string>>;
