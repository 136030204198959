import { type PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { removeItemsFromStorage } from 'types';

export type Filters = {
  accountIds: string[],
  fundIds: string[],
  organizationId: string,
};

export type FilterStoreState = {
  activeFilters: Filters | null,
};

const readFiltersFromSessionStorage = (readFrom: 'activeFilters') => {
  const stringifiedFilters = sessionStorage.getItem(readFrom);

  if (!stringifiedFilters) {
    return null;
  }

  try {
    const parsedFilters = JSON.parse(stringifiedFilters) as Filters;
    return parsedFilters;
  } catch {
    return null;
  }
};

const initialState: FilterStoreState = {
  activeFilters: readFiltersFromSessionStorage('activeFilters'),
};

const filtersSlice = createSlice({
  initialState,
  name: 'filters',
  reducers: {
    resetActiveFilters: () => {
      removeItemsFromStorage([
        'activeFilters',
      ], sessionStorage);
      return {...initialState};
    },
    setActiveFilters: (
      state,
      action: PayloadAction<Filters>,
    ) => {
      const { payload } = action;
      state.activeFilters = payload;
      sessionStorage.setItem('activeFilters', JSON.stringify(payload));
    },
  },
});

const filtersReducer = filtersSlice.reducer;

export const {
  setActiveFilters,
  resetActiveFilters,
} = filtersSlice.actions;

export { filtersReducer };

