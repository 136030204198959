import { MAX_NAME_LENGTH } from './OrganizationColumn';
import { Tooltip } from 'antd';
import { Checkbox } from 'components/InteractiveUIControls/Checkbox/Checkbox';
import { limitString } from 'components/Pipes/textPipes';
import { type FC,
  useEffect,
  useMemo,
  useState} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import {
  filterOrganizationByFund,
  type OrganizationsStoreState,
} from 'store/slices';
import { type SelectedFund } from 'types';

type FundOptionProps = {
  fund: SelectedFund,
  onClick: (id: string) => void,
  selected: boolean,
};

export type NewFundAndAccountIds = {
  newSelectedAccountIds?: string[],
  newSelectedFundIds?: string[],
};

const FundOption: FC<FundOptionProps> = ({ fund,
  selected,
  onClick }) => {
  const fundName = () => <span className='text-font-2'>
    {limitString(fund.fundName, MAX_NAME_LENGTH)}
  </span>;
  return (
    <div
      className='flex w-full cursor-pointer items-center gap-0.75
      rounded-main px-0.5 py-0.75 hover:bg-black-100'
      data-test={`fund-${fund.fundName}`}
      onClick={() => onClick(fund.fundId)}
    >
      <Checkbox readOnly selected={selected} />
      { fund.fundName.length > MAX_NAME_LENGTH ?
        <Tooltip title={fund.fundName}>{fundName()}</Tooltip> : fundName()}
    </div>
  );
};

export const FundsColumn: FC = () => {
  const dispatch = useDispatch();
  const {
    organizations,
    displayedOrganization } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const currentOrganization = organizations
    ?.find((org) => org.organizationId === displayedOrganization?.organizationId);

  const [
    allSelected,
    setAllSelected,
  ] = useState(currentOrganization?.funds
    .length === displayedOrganization?.funds?.length);

  const selectedFundIds = useMemo(() => {
    return displayedOrganization?.funds?.map((fund) => fund.fundId) || [];
  }, [
    displayedOrganization,
  ]);

  useEffect(() => {
    setAllSelected(currentOrganization?.funds
      .length === displayedOrganization?.funds?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayedOrganization,
  ]);

  const isOptionSelected = (id: string) => {
    return selectedFundIds.includes(id);
  };

  const checkFund = (fundId: string) => {
    if (selectedFundIds.includes(fundId)) {
      dispatch(
        filterOrganizationByFund(selectedFundIds
          .filter((fund) => fund !== fundId) as string[]),
      );
    } else {
      dispatch(
        filterOrganizationByFund([
          ...selectedFundIds,
          fundId,
        ]),
      );
    }
  };

  const toggleAll = () => {
    const newAllSelected = !allSelected;
    dispatch(
      filterOrganizationByFund(allSelected ? [] : currentOrganization
        ?.funds.map((fund) => fund.fundId) as string[]),
    );

    setAllSelected(newAllSelected);
  };

  return (
    <div className='column-width flex flex-col items-start gap-0.75'>
      <div className='flex w-full items-center justify-between'>
        <span className='p-0.5 text-font-1 font-medium'>Funds</span>
        <span
          className='cursor-pointer text-font-2 text-black-700 hover:text-main-700'
          data-test={'clear-funds'}
          onClick={toggleAll}
        >
          {`${allSelected ? 'Clear' : 'Select'} all`}
        </span>
      </div>
      <div className='funds-column'>
        {currentOrganization?.funds?.map((fund) =>
          <FundOption
            fund={fund}
            key={fund.fundId}
            onClick={() => checkFund(fund.fundId)}
            selected={isOptionSelected(fund.fundId)}
          />,
        )}
      </div>
    </div>
  );
};
