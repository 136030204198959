import { Alert } from '../../../api/utils';
import {
  NoFilesMessage,
  Spinner,
} from '../../../components';
import { GlobalElementBusContext } from '../../../contexts';
import {
  useDocumentTitle,
  useRelativePathsToBasePath,
} from '../../../hooks';
import {
  type CapitalAccountsStoreState,
  fetchCapitalAccounts,
  type FilterStoreState,
  type OrganizationsStoreState,
} from '../../../store/slices';
import {
  type Account,
  type CapitalAccountPeriodRecord,
} from '../../../types';
import style from './CapitalAccounts.module.scss';
import { CapitalAccountsDetails } from './CapitalAccountsDetails';
import { CapitalAccountsSummary } from './CapitalAccountsSummary';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { isMobile } from 'services/mobile';
import { type AppDispatch } from 'store';

const VALID_PATHS_FOR_ACCOUNT_NAME = [
  '/firm/capital-accounts',
];

const CapitalAccounts = () => {
  const { accountId,
    fundId } = useParams();
  const mobile = isMobile();
  const { valid } = useRelativePathsToBasePath(...VALID_PATHS_FOR_ACCOUNT_NAME);
  const {
    capitalAccountsDataError,
    capitalAccountsDataPending,
    computedCapitalAccountsData,
  } = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;

  const organizationId = useSelector<{ organizations: OrganizationsStoreState, }>(
    (state) => state
      .organizations
      .displayedOrganization
      ?.organizationId,
  ) as string | undefined;
  const periods = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .capitalAccountsData
      ?.find((fund) => fund._id === fundId)
      ?.accounts
      .find((account) => account._id === accountId)
      ?.periods,
  ) as CapitalAccountPeriodRecord | undefined;

  const accounts = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .capitalAccountsData
      ?.find((fund) => fund._id === fundId)
      ?.accounts,
  ) as Account[];

  const accountIds = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters?.activeFilters?.accountIds) as string[];

  useDocumentTitle('Capital Accounts');
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const {
    addRef,
  } = useContext(GlobalElementBusContext);

  const [
    isFirstQuery,
    setIsFirstQuery,
  ] = useState(true);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    if (!isFirstQuery && computedCapitalAccountsData) {
      return;
    }

    dispatch(fetchCapitalAccounts({
      accountIds,
      firmId: organizationId,
    }));

    setIsFirstQuery(false);
  }, [
    computedCapitalAccountsData,
    dispatch,
    isFirstQuery,
    navigate,
    organizationId,
    accountIds,
  ]);

  useEffect(() => {
    if (capitalAccountsDataError) {
      Alert(capitalAccountsDataError);
    }
  }, [
    capitalAccountsDataError,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      addRef(containerRef);
    }
  }, [
    addRef,
    containerRef,
  ]);

  if (!computedCapitalAccountsData || computedCapitalAccountsData.length === 0) {
    // eslint-disable-next-line react/jsx-boolean-value
    return <NoFilesMessage capitalAccounts={true} />;
  }

  if (capitalAccountsDataPending) {
    return <div className='flex min-h-[45rem] items-center'><Spinner /></div>;
  }

  return (
    <div className={style.capitalAccountsContainer}>
      {mobile && valid && <h1 className='!m-0 text-title-3'>
        Digital capital account
      </h1>}
      {accountId && fundId && periods ?
        <CapitalAccountsDetails
          accountId={accountId}
          accounts={accounts}
          fundId={fundId}
          periods={periods}
        /> :
        <CapitalAccountsSummary />
      }
    </div>
  );
};

export { CapitalAccounts };
