import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Svgicon } from 'components/Svgicon/Svgicon';
import { ModalActivationContext } from 'contexts';
import { useContext } from 'react';
import { isMobile } from 'services/mobile';

export const DownloadAllFilesBlock = () => {
  const { toggleDownloadsModal } = useContext(ModalActivationContext);
  const mobile = isMobile();

  const onClickDownload = () => {
    toggleDownloadsModal(true);
  };

  return (
    mobile ?
      <div className='flex w-full items-center gap-0.25' data-test='download-all-files' onClick={onClickDownload}>
        <Svgicon className='size-1' id='download-cloud' />
        <span className='text-font-3'>Download</span>
      </div> :
      <Button data-test='download-all-files' icon='left' iconid='download-cloud' onClick={onClickDownload} type='grey'>
        Download
      </Button>
  );
};
