import performanceReportImage from '../../../assets/images/performance-report-modal-dashboard.png';
import { useResize } from '../../../hooks';
import { MAX_ALLOWABLE_MODULE_WIDTH_FOR_CA_IMG } from '../../../resources/constants';
import { type CapitalAccountsStoreState } from '../../../store/slices';
import backgroundImage from './capital-accounts-bg.png';
import style from './CapitalAccountsBanner.module.scss';
import { type CSSProperties,
  useMemo} from 'react';
import {
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CapitalAccountsBanner = () => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const {width} = useResize(containerRef);
  const {
    hasCapitalAccounts,
    hasCapitalAccountsPending,
  } = useSelector<{capitalAccounts: CapitalAccountsStoreState, }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  const containerStyle = useMemo(() => {
    const newContainerStyle: CSSProperties = {};

    if (width > MAX_ALLOWABLE_MODULE_WIDTH_FOR_CA_IMG) {
      newContainerStyle.backgroundImage
      = `url(${performanceReportImage}), url(${backgroundImage})`;
      newContainerStyle.backgroundSize = 'contain, cover';
    } else {
      newContainerStyle.backgroundImage = `url(${backgroundImage})`;
      newContainerStyle.backgroundSize = 'cover';
    }

    if (hasCapitalAccountsPending || !hasCapitalAccounts) {
      newContainerStyle.display = 'none';
    }

    return newContainerStyle;
  }, [
    hasCapitalAccounts,
    hasCapitalAccountsPending,
    width,
  ]);

  const onViewButtonClick = () => navigate('/firm/capital-accounts');

  return (
    <div
      className={style.container}
      ref={containerRef}
      style={containerStyle}
    >
      <div className={style.leftBlock}>
        <h3 className={style.title}>
          Digital Capital Accounts
        </h3>
        <button
          className={style.button}
          data-test={'view-capital-accounts-button'}
          onClick={onViewButtonClick}
          type='button'
        >
          <p className={style.viewText}>
            View
          </p>
        </button>
      </div>
    </div>
  );
};
