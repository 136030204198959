import {
  CapitalAccountsBanner,
  DocumentCategories,
  ReportsModule,
} from '../../../components';
import { useDocumentTitle } from '../../../hooks';
import {
  type CapitalAccountsStoreState,
  type DocumentsStoreState,
  fetchCapitalAccountsPreview,
  fetchCategories,
  fetchReports,
  type Filters,
  type FilterStoreState} from '../../../store/slices';
import style from './Documents.module.scss';
import classNames from 'classnames';
import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';

export const Documents = () => {
  const {
    reports,
    newCategory,
    otherCategories,
    categoriesPending,
  } = useSelector<{ documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  const {
    hasCapitalAccounts,
  } = useSelector<{ capitalAccounts: CapitalAccountsStoreState, }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  useDocumentTitle('Investor Portal');
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!activeFilters) {
      navigate('/firms');
      return;
    }

    dispatch(fetchCategories({
      ...activeFilters,
      firmId: activeFilters.organizationId,
    }));

    dispatch(fetchReports({
      firmId: activeFilters.organizationId,
      fundIds: activeFilters.fundIds,
    }));

    dispatch(fetchCapitalAccountsPreview({
      accountIds: activeFilters.accountIds,
      firmId: activeFilters.organizationId,
    }));
  }, [
    dispatch,
    activeFilters,
    navigate,
  ]);

  return (
    <div className={classNames(style.documentsContainer, { 'h-full': categoriesPending || !newCategory && !otherCategories?.length })}>
      {(reports?.length || hasCapitalAccounts) &&
      <div className={style.digitalReportsContainer}>
        {reports && reports.length > 0 && <ReportsModule />}
        {hasCapitalAccounts && <CapitalAccountsBanner />}
      </div>}
      <DocumentCategories />
    </div>
  );
};
