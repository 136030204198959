import { documentsApi } from 'api/documentsApi';
import filesApi from 'api/files-v2';
import { actionCreator } from 'api/utils';
import { PdfReader,
  Spinner } from 'components';
import { Modal } from 'components/InteractiveUIControls/Modal/Modal';
import { ModalTop } from 'components/InteractiveUIControls/Modal/Modaltop';
import { ModalActivationContext } from 'contexts';
import { type FC,
  useContext,
  useEffect,
  useState} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { useLocation,
  useNavigate } from 'react-router-dom';
import { type AppDispatch } from 'store';
import { filesActionTypes } from 'store/actions';
import { markDocumentsAsSeen } from 'store/slices';
import {
  type DocumentDetailsFromStorage,
  type IndividualFile,
  type LoginAsFromStorage,
  removeItemsFromStorage} from 'types';
import { getItemsFromStorage } from 'types';

export type IndividualFileStore = {
  fileByIdLoading: boolean,
  viewingFileByIdLoading: boolean,
};

export type FilesStore = {
  files: IndividualFileStore,
};

export const FileViewModal: FC<{docId: string | null,}> =
  ({docId}: {docId: string | null,}) => {
    const navigate = useNavigate();
    const {pathname,
      search} = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const queryParameters = new URLSearchParams(search);
    const { fileViewId } = Object.fromEntries(queryParameters.entries());
    const {loginAs} = getItemsFromStorage<LoginAsFromStorage>([
      'loginAs',
    ], sessionStorage);
    const {
      categoryId,
      documentGroupId,
      documentId,
      isNewDocument,
    } = getItemsFromStorage<DocumentDetailsFromStorage>([
      'categoryId',
      'documentGroupId',
      'documentId',
      'isNewDocument',
    ], sessionStorage);
    const {
      fileByIdLoading,
    } = useSelector<FilesStore>((state) => state.files) as IndividualFileStore;
    const { toggleFileViewModal } = useContext(ModalActivationContext);

    const [
      fileById,
      setFileById,
    ] = useState<IndividualFile | null>(null);

    useEffect(() => {
      (async () => {
        if (!docId) {
          return;
        }

        dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, true));

        if ((!loginAs || loginAs === 'false') && docId) {
          await documentsApi.viewDocuments({documentIds: [
            docId,
          ]});
        }

        setFileById(await filesApi.getFileByIdAsync(docId, dispatch));

        if (categoryId && documentGroupId && documentId && isNewDocument === 'true') {
          dispatch(markDocumentsAsSeen({
            categoryId,
            documentGroupId,
            documentIds: [
              documentId,
            ],
          }));
        }

        dispatch(actionCreator(filesActionTypes.VIEWING_FILE_BY_ID_LOADING, false));
      })();
    }, [
      categoryId,
      dispatch,
      docId,
      documentGroupId,
      documentId,
      isNewDocument,
      loginAs,
    ]);

    const closeFileViewModal = () => {
      toggleFileViewModal(false, null);
      removeItemsFromStorage<DocumentDetailsFromStorage>([
        'categoryId',
        'documentGroupId',
        'documentId',
        'isNewDocument',
      ], sessionStorage);
      setFileById(null);
      if (fileViewId) {
        navigate(pathname);
      }
    };

    return <Modal onToggle={closeFileViewModal} showCloseIcon >
      <ModalTop title={!fileById || fileByIdLoading ? '' : fileById.fileName} />
      <div className='h-[calc(100vh-3.5rem)] dt:h-[85vh] dt:w-[80vw] dt:p-1.25'>
        {fileById ?
          <PdfReader
            style={{ height: '100%',
              width: '100%' }}
            title={fileById.fileName}
            url={fileById.url}
          /> :
          <Spinner />}
      </div>
    </Modal>;
  };
