/* eslint-disable canonical/id-match */
/* eslint-disable max-len */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable canonical/filename-match-regex */
/* eslint-disable canonical/filename-match-exported */
import {filesActionTypes} from '../store/actions';
import Api from '.';
import {actionCreator,
  Alert,
  unauthorized} from './utils';
import fileDownload from 'react-file-download';
import {notificationsService} from 'services';

const getUrlParameters = (fileType, firmId, fundId, accountIds) => {
  const reduced = accountIds.reduce((a, b) => [
    a,
    b,
  ].join('&accountIds='), '');
  const joinedParameterString = [
    `?firmId=${firmId}&fundId=${fundId}`,
    reduced,
  ].join('');
  return [
    fileType ? `/type/${fileType}` : '',
    joinedParameterString,
  ].join('');
};

const getUrlParametersForSingleFile = (fileId) => {
  return `/${fileId}`;
};

class FilesApi extends Api {
  constructor () {
    super('/api/v2/files');
  }

  getFiles ({firmId,
    fundId = '',
    accountIds = []}) {
    return async (dispatch) => {
      dispatch(actionCreator(filesActionTypes.FILE_LOADING, true));
      const url = getUrlParameters('', firmId, fundId, accountIds);
      try {
        const {data} = await this.get(url);
        dispatch(actionCreator(filesActionTypes.GET_FIRM_FILES_SUCCESS, data));
      } catch (error) {
        unauthorized(error?.response?.status);
        Alert(error?.response?.data?.message);
      } finally {
        dispatch(actionCreator(filesActionTypes.FILE_LOADING, false));
      }
    };
  }

  getFilesByType ({fileType,
    firmId,
    fundId = '',
    accountIds = []}) {
    return async (dispatch) => {
      dispatch(actionCreator(filesActionTypes.FILE_BY_FILETYPE_LOADING, true));
      const url = getUrlParameters(fileType, firmId, fundId, accountIds);
      try {
        const {data} = await this.get(url);
        dispatch(actionCreator(filesActionTypes.GET_FIRM_FILES_BY_FILETYPE_SUCCESS, data));
      } catch (error) {
        unauthorized(error?.response?.status);
        Alert(error?.response?.data?.message);
      } finally {
        dispatch(actionCreator(filesActionTypes.FILE_BY_FILETYPE_LOADING, false));
      }
    };
  }

  async getFileByIdAsync (fileId, dispatch) {
    const url = getUrlParametersForSingleFile(fileId);
    let data = null;
    try {
      data = await this.get(url);
    } catch (error) {
      dispatch(actionCreator(filesActionTypes.FILE_BY_ID_LOADING, false));
      unauthorized(error?.response?.status);
      Alert(error?.response?.data?.message);
    }

    return data?.data;
  }

  downloadAllFiles (body) {
    return async (dispatch) => {
      dispatch(actionCreator(filesActionTypes.DOWNLOAD_FILES_LOADING, true));
      try {
        const {data} = await this.post('/download-files', body, {responseType: 'blob'});
        fileDownload(data, 'Files.zip');
      } catch {
        notificationsService.error('Some files does not exist.');
      } finally {
        dispatch(actionCreator(filesActionTypes.DOWNLOAD_FILES_LOADING, false));
      }
    };
  }
}

const filesV2Api = new FilesApi();
export default filesV2Api;
