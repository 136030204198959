import { type SelectDisplayOption,
  type SelectOption } from './types';
import { useEffect,
  useState } from 'react';

export const ALL_OPTIONS_ID = 'ALL_OPTIONS_ID';

const ALL_OPTIONS_PLACEHOLDER = {
  id: ALL_OPTIONS_ID,
  name: 'All',
  selected: true,
};

type SelectorProps = {
  isDynamicOption?: boolean,
  optionList: SelectOption[],
  selectedIds: string[],
  title: string,
};

const getInitialOptions = (optionList: SelectOption[]) => {
  const mappedOptions = optionList.map((option) => ({
    id: option.id,
    name: option.display,
    selected: true,
  })) as SelectDisplayOption[];

  const newOptions = [
    { ...ALL_OPTIONS_PLACEHOLDER },
  ].concat(mappedOptions);

  return newOptions;
};

const toggleAllOptions = (options: SelectDisplayOption[]) => {
  const wereAllSelected = options[0].selected;
  const newOptions = options.map((option) => {
    return {
      ...option,
      selected: !wereAllSelected,
    };
  });

  return newOptions;
};

const getAllSelected = (options: SelectDisplayOption[]) => {
  const optionsWithoutSelectAll = options
    .filter((option) => option.id !== ALL_OPTIONS_ID);
  const allSelected = optionsWithoutSelectAll.every((option) => option.selected);
  if (allSelected) {
    return true;
  } else if (optionsWithoutSelectAll.every((option) => !option.selected)) {
    return false;
  }

  // Not changed
  return options[0].selected;
};

const toggleOption = (options: SelectDisplayOption[], optionId: string) => {
  // if all are toggled
  if (optionId === ALL_OPTIONS_ID) {
    return toggleAllOptions(options);
  }

  const newOptions = [
    ...options,
  ];
  const triggeredOption = newOptions.find((option) => option.id === optionId);

  if (!triggeredOption) {
    return toggleAllOptions(options);
  }

  triggeredOption.selected = !triggeredOption.selected;

  newOptions[0].selected = getAllSelected(newOptions);
  return newOptions;
};

const getDisplay = ({
  options,
  title}:
{options: SelectDisplayOption[], title: string,},
) => {
  if (options.length <= 1) {
    return `${title}`;
  } else if (options.every((option) => option.selected)) {
    return `All ${title.toLowerCase()}s`;
  }

  const selectedAmount = options.slice(1).filter((option) => option.selected).length;

  if (selectedAmount === 0) {
    return `${title}`;
  }

  return `${selectedAmount} ${title.toLowerCase()}${selectedAmount === 1 ? '' : 's'}`;
};

/**
 * Hook that tracks the selected options for given
 * list of options to select from.
 */
const useMultiSelector = ({
  optionList,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isDynamicOption,
  title,
  selectedIds }: SelectorProps,
) => {
  const initialOptions = getInitialOptions(optionList);
  const [
    options,
    setOptions,
  ] = useState<SelectDisplayOption[]>(initialOptions);

  const [
    display,
    setDisplay,
  ] = useState(options.length > 1 ? `All ${title.toLowerCase()}s` : `${title}`);

  const refreshOptions = (newOptionList: SelectOption[], newIds: string[]) => {
    const newOptions = getInitialOptions(newOptionList);
    const optionsWithoutSelectAll = newOptions.slice(1);
    newOptions[0].selected = options[0].selected;

    if (!newIds.length || newIds.length === optionsWithoutSelectAll.length) {
      // toggle all
      const isSelected = Boolean(newIds.length);
      for (const option of newOptions) {
        option.selected = isSelected;
      }

      setOptions(newOptions);
    } else {
      for (const option of optionsWithoutSelectAll) {
        option.selected = newIds.includes(option.id);
      }

      newOptions[0].selected = getAllSelected(newOptions);
      setOptions(newOptions);
    }

    setDisplay(
      getDisplay({
        options: newOptions,
        title,
      }),
    );
  };

  const markOption = (optionId: string) => {
    const newOptions = toggleOption(options, optionId);
    setOptions(newOptions);
    setDisplay(getDisplay({options: newOptions,
      title}));
    return newOptions;
  };

  const isAllOption = (option: SelectDisplayOption) => {
    return option.name === ALL_OPTIONS_PLACEHOLDER.name &&
      option.id === ALL_OPTIONS_ID;
  };

  useEffect(() => {
    refreshOptions(optionList, selectedIds);
  }, [
    optionList,
    selectedIds,
  ]);

  return {
    display,
    isAllOption,
    markOption,
    options,
  };
};

export { useMultiSelector };
