export type DoubleTitleTypes = {
  subTitle?: string,
  title: string,
};

export const DoubleTitle = ({
  title,
  subTitle,
}: DoubleTitleTypes) => <>
  <span className='block text-title-1'>{title}</span>
  {subTitle && <span className='block text-title-3 text-black-700'>{subTitle}</span>}
</>;
