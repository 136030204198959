// import { Table } from '../../Table';

import { type CapitalAccountsStoreState } from '../../../store/slices';
import {
  ColumnsToDisplay,
  type ComputedCapitalAccountFund,
  SecondTableColumns,
} from '../../../types';
import {
  getBlankColumn,
  Table,
} from '../../Table';
import { CapitalAccountsExpandIcon } from './CapitalAccountsExpandIcon';
import { CapitalAccountsFundTable } from './CapitalAccountsFundTable';
import { CapitalAccountsSummaryTableRow } from './CapitalAccountsSummaryTableRow';
import { DoubleTitle } from './DoubleTitle';
import { Table as AntTable } from 'antd';
import { type ColumnsType } from 'antd/lib/table';
import {
  type FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

const HORIZONTAL_SCROLL_THRESHOLD = 58;

const CapitalAccountsSummaryTable: FC = () => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [
    dropdownCloseSignal,
    setDropdownCloseSignal,
  ] = useState(false);
  const {
    computedCapitalAccountsData,
    selectedFundAccountPeriodMap,
  } = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state.capitalAccounts,
  ) as CapitalAccountsStoreState;
  const [
    expandedRowKeys,
    setExpandedRowKeys,
  ] = useState<string[]>([]);

  useEffect(() => {
    if (
      computedCapitalAccountsData?.length &&
      !expandedRowKeys.length
    ) {
      setExpandedRowKeys(computedCapitalAccountsData.map((item) => item._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    computedCapitalAccountsData,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        tableContainerRef.current?.scrollLeft &&
        tableContainerRef.current?.scrollLeft > HORIZONTAL_SCROLL_THRESHOLD) {
        setDropdownCloseSignal(true);
      } else {
        setDropdownCloseSignal(false);
      }
    };

    const container = tableContainerRef.current;

    container?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const columns: ColumnsType<ComputedCapitalAccountFund> = [
    AntTable.EXPAND_COLUMN,
    {
      dataIndex: 'legalName',
      key: 'legalName',
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.investor.subtitle}
        title={ColumnsToDisplay.investor.title}
      />,
      width: '8.125rem',
    },
    {
      title: <DoubleTitle
        title={ColumnsToDisplay.reportingPeriod.title}
      />,
      width: '6.25rem',
    },
    {
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.commitment.subtitle}
        title={ColumnsToDisplay.commitment.title}
      />,
      width: '4.875rem',
    },
    getBlankColumn(),
    ...SecondTableColumns.map((column) => ({
      title: <DoubleTitle subTitle={column[1].subtitle} title={column[1].title} />,
      width: column[0] === 'contribution' ? 80 : 78,
    })),
    getBlankColumn(),
    {
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.other.subtitle}
        title={ColumnsToDisplay.other.title}
      />,
      width: '4.875rem',
    },
    getBlankColumn(),
    {
      title: <DoubleTitle
        subTitle={ColumnsToDisplay.nav.subtitle}
        title={ColumnsToDisplay.nav.title}
      />,
      width: '4.875rem',
    },
  ];

  if (!computedCapitalAccountsData) {
    return null;
  }

  const getSummaryRowComponent = () => <CapitalAccountsSummaryTableRow
    data={computedCapitalAccountsData}
    selectedFundAccountPeriodMap={selectedFundAccountPeriodMap}
  />;

  return <div className={'zoom-125 z-0 -mt-14 overflow-x-auto overflow-y-hidden pt-14'} ref={tableContainerRef}>
    <Table
      className='ant-table-without-top-border rotateX-180 min-w-[56.375rem]'
      columns={columns}
      dataSource={computedCapitalAccountsData}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowKeys,
        expandedRowRender: (
          record: ComputedCapitalAccountFund,
        ) => <CapitalAccountsFundTable
          dropdownCloseSignal={dropdownCloseSignal}
          record={record}
          selectedAccountPeriodMap={selectedFundAccountPeriodMap[record._id]}
          withoutSubtotalSummary={computedCapitalAccountsData.length === 1}
        />,
        expandIcon: CapitalAccountsExpandIcon,
        onExpand: (expanded, record) => {
          // eslint-disable-next-line max-len
          setExpandedRowKeys(expanded ? expandedRowKeys.concat(record._id) : expandedRowKeys.filter((key) => key !== record._id));
        },
      }}
      rowKey={'_id'}
      summary={getSummaryRowComponent}
    />
  </div>;
};

export { CapitalAccountsSummaryTable };
