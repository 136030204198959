export const storeItemsInStorage = <
  T extends Record<string, string>
>(dataToBeStored: Partial<T>, storage: SessionStorageInterface) => {
  const kvPairs = Object.entries(dataToBeStored);

  for (const kvPair of kvPairs) {
    const key = kvPair[0];
    const value = kvPair[1];

    if (value) {
      storage.setItem(key, value);
    }
  }
};

export const removeItemsFromStorage = <
  T extends Partial<Record<string, string>>
>(
  keysToRemove: Array<keyof T>,
  storage: SessionStorageInterface,
) => {
  for (const key of keysToRemove) {
    storage.removeItem(key as string);
  }
};

export const getItemsFromStorage = <
  T extends Partial<Record<string, string>>
>(
  keysToGet: Array<keyof T>,
  storage: SessionStorageInterface,
) => {
  const result: Partial<T> = {};

  for (const key of keysToGet) {
    const value = storage.getItem(key as string);

    if (value === null) {
      result[key] = undefined;
    } else {
      result[key] = value as T[keyof T];
    }
  }

  return result;
};

export type SessionStorageInterface = {
  getItem: (key: string) => string | null,
  removeItem: (key: string) => void,
  setItem: (key: string, value: string) => void,
};

export type PrivacyPolicyFromStorage = {
  privacyPolicy: 'false' | 'true',
};

export type LoginAsFromStorage = {
  loginAs: 'false' | 'true',
};

export type PageRedirectInfoFromStorage = {
  loginMessage: string,
  nextPage: string,
};

export type DocumentDetailsFromStorage = {
  categoryId: string,
  documentGroupId: string,
  documentId: string,
  isNewDocument: 'false' | 'true',
};

export type NextPageAfterVerificationFromStorage = {
  nextPageAfterVerification: string,
};
