import { documentsApi } from '../../../api/documentsApi';
import { Alert } from '../../../api/utils';
import environment from '../../../config/environment';
import { INITIAL_SELECT_OPTION_STATE,
  type SelectOption } from '../../../hooks';
import { type IReportGroup } from '../../../types';
import { Dropdown } from '../../Dropdown';
import style from './ReportSelector.module.scss';
import {
  type FC,
  useState,
} from 'react';

const BASE_FMS_URL = environment.fmsLoginUrl;

type ReportsSelectorProps = {
  reports: IReportGroup[],
};

const getFMSUrl = async (reportId: string) => {
  const token = await documentsApi.getReportToken({ reportId });
  const FMSUrl = `${BASE_FMS_URL}/published-investor-report?token=${token}`;
  return FMSUrl;
};

export const ReportSelector: FC<ReportsSelectorProps> = ({
  reports,
}) => {
  const fundOptions = reports.map((group) => ({
    display: group.fundName,
    id: group.fundId,
  }));

  const fundAndPeriodOptions = reports.map((group) => ({
    fundOption: {
      display: group.fundName,
      id: group.fundId,
    },
    periodOptions: group.reports.map((report) => ({
      display: new Date(report.period).toLocaleDateString('en-CA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }),
      id: report._id,
    })),
  }));

  const [
    selectedFund,
    setSelectedFund,
  ] = useState(fundAndPeriodOptions[0].fundOption);

  const [
    periodOptions,
    setPeriodOptions,
  ] = useState<SelectOption[]>(fundAndPeriodOptions[0].periodOptions);

  const [
    selectedPeriodOption,
    setSelectedPeriodOption,
  ] = useState(periodOptions.length > 0 ? periodOptions[0] : INITIAL_SELECT_OPTION_STATE);

  const onSelectFund = (newValue: SelectOption) => {
    const fundReportsGroup = fundAndPeriodOptions.find(
      (group) => group.fundOption.id === newValue.id,
    );

    if (!fundReportsGroup) {
      return;
    }

    const newFundOption = fundReportsGroup.fundOption;

    const newPeriodOptions = fundReportsGroup.periodOptions;

    const selectedPeriod = newPeriodOptions.length > 0
      ? newPeriodOptions[0]
      : INITIAL_SELECT_OPTION_STATE;

    setSelectedFund(newFundOption);
    setPeriodOptions(newPeriodOptions);
    setSelectedPeriodOption(selectedPeriod);
  };

  const onSelectPeriod = (newValue: SelectOption) => {
    // Just set our period
    setSelectedPeriodOption(newValue);
  };

  const onViewButtonClick = async () => {
    if (!selectedPeriodOption.id) {
      Alert('Please select a reporting period to view!');
      return;
    }

    const newWindow = window.open('', '_blank');
    const url = await getFMSUrl(selectedPeriodOption.id);
    if (newWindow) {
      newWindow.location.href = url;
    }
  };

  return <>
    <Dropdown
      className='dt:!w-[17.5rem]'
      dataSelectionValue='report-fund-selector-dropdown'
      displayHeight='2rem'
      optionList={fundOptions}
      placeholder='Fund'
      rounded
      setValue={onSelectFund}
      value={selectedFund}
    />
    <div className='flex gap-0.375'>
      {
        periodOptions.length > 0 && <Dropdown
          className={'dt:!w-[9.25rem]'}
          dataSelectionValue='report-period-selector-dropdown'
          displayHeight='2rem'
          optionList={periodOptions}
          placeholder='Reporting Periods'
          rounded
          setValue={onSelectPeriod}
          value={selectedPeriodOption}
        />
      }
      {
        selectedPeriodOption.id && <button
          className={style.button}
          data-test={'view-report-button'}
          onClick={onViewButtonClick}
          type='button'
        >
          <p className={style.viewText}>
            View
          </p>
        </button>
      }
    </div>
  </>;
};
