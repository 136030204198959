import {
  resetActiveFilters,
  resetDocuments,
  resetOrganizations,
} from '../slices';
import {actionCreator} from 'api/utils';
import {
  authActionTypes,
  filesActionTypes,
  firmActionTypes,
  firmsActionTypes,
  reportsActionTypes,
  rootActionTypes,
  supportActionTypes,
} from 'store/actions';

const RESET_ACTIONS = [
  authActionTypes.RESET,
  resetActiveFilters().type,
  firmActionTypes.RESET,
  firmsActionTypes.RESET,
  supportActionTypes.RESET,
  filesActionTypes.RESET,
  reportsActionTypes.RESET,
  resetOrganizations().type,
  resetDocuments().type,
];

const resetState = (actionList) => {
  return (dispatch) => {
    for (const resetAction of actionList) {
      dispatch(actionCreator(resetAction, false));
    }
  };
};

const clearStore = ({dispatch}) => (next) => (action) => {
  if (!action.type || action.type !== rootActionTypes.CLEAR_STORE) {
    return next(action);
  }

  return dispatch(resetState(RESET_ACTIONS));
};

export default clearStore;
