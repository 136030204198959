import { MAX_NAME_LENGTH } from './OrganizationColumn';
import { Tooltip } from 'antd';
import { Checkbox } from 'components/InteractiveUIControls/Checkbox/Checkbox';
import { limitString } from 'components/Pipes/textPipes';
import {
  type FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  filterOrganizationByAccounts,
  type OrganizationsStoreState,
} from 'store/slices';
import {
  type NestedAccount,
  type NestedOrganization,
} from 'types';

type AccountOptionProps = {
  account: NestedAccount,
  onClick: (id: string) => void,
  selected: boolean,
};

const AccountOption: FC<AccountOptionProps> = ({
  account,
  selected,
  onClick,
}) => {
  const accountName = () => <span className='text-font-2'>
    {limitString(account.accountName, MAX_NAME_LENGTH)}
  </span>;
  return (
    <div
      className='flex w-full cursor-pointer
      items-center gap-0.75 rounded-main px-0.5 py-0.75 hover:bg-black-100'
      data-test={`account-${account.accountName}`}
      onClick={() => onClick(account.accountId)}
    >
      <Checkbox readOnly selected={selected} />
      {account.accountName.length > MAX_NAME_LENGTH ?
        <Tooltip title={account.accountName}>{accountName()}</Tooltip> : accountName()}
    </div>
  );
};

const getAllAccounts = (displayedOrganization: NestedOrganization | null) => {
  return displayedOrganization?.funds.flatMap((fund) => fund.accounts) ?? [];
};

export const AccountsColumn: FC = () => {
  const dispatch = useDispatch();

  const {
    organizations,
    displayedOrganization } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const currentOrganization = organizations
    ?.find((org) => org.organizationId ===
      displayedOrganization?.organizationId) as NestedOrganization;

  const [
    allSelected,
    setAllSelected,
  ] = useState(getAllAccounts(currentOrganization).length
    === getAllAccounts(displayedOrganization).length);

  const selectedAccountIds = useMemo(() => {
    return getAllAccounts(displayedOrganization).map((acc) => acc.accountId) || [];
  }, [
    displayedOrganization,
  ]);

  useEffect(() => {
    setAllSelected(getAllAccounts(currentOrganization).length
      === getAllAccounts(displayedOrganization).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayedOrganization,
  ]);

  const isOptionSelected = (id: string) => {
    return selectedAccountIds.includes(id);
  };

  const checkAccount = (accountId: string) => {
    if (selectedAccountIds.includes(accountId)) {
      dispatch(
        filterOrganizationByAccounts(selectedAccountIds
          .filter((acc) => acc !== accountId) as string[]),
      );
    } else {
      dispatch(
        filterOrganizationByAccounts([
          ...selectedAccountIds,
          accountId,
        ]),
      );
    }
  };

  const toggleAll = () => {
    const newAllSelected = !allSelected;
    dispatch(
      filterOrganizationByAccounts(allSelected ? [] :
        getAllAccounts(currentOrganization)
          .map((acc) => acc.accountId) as string[]),
    );

    setAllSelected(newAllSelected);
  };

  return (
    <div className='column-width flex flex-col items-start gap-0.75'>
      <div className='flex w-full items-center justify-between'>
        <span className='p-0.5 text-font-1 font-medium'>Accounts</span>
        <span
          className='cursor-pointer text-font-2 text-black-700 hover:text-main-700'
          data-test={'clear-accounts'}
          onClick={toggleAll}
        >
          {`${allSelected ? 'Clear' : 'Select'} all`}
        </span>
      </div>
      <div className='accounts-column'>
        {getAllAccounts(currentOrganization).length ?
          getAllAccounts(currentOrganization).map((acc) =>
            <AccountOption
              account={acc}
              key={acc.accountId}
              onClick={() => checkAccount(acc.accountId)}
              selected={isOptionSelected(acc.accountId)}
            />,
          ) : <span className='flex size-full items-center justify-center text-font-2 text-black-700'>
            No fund selected
          </span>}
      </div>
    </div>
  );
};
