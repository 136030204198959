import {
  type ComputedCapitalAccountFund } from '../../../../types';
import { Button } from '../../../InteractiveUIControls/Button/Button';
import {type FC,
  type MouseEvent } from 'react';

export type CapitalAccountsExpandIconProps = {
  expanded: boolean,
  onExpand: (record: ComputedCapitalAccountFund, event: MouseEvent<HTMLElement>) => void,
  record: ComputedCapitalAccountFund,
};

export const CapitalAccountsExpandIcon: FC<CapitalAccountsExpandIconProps> = ({
  expanded,
  onExpand,
  record,
}) => {
  return <Button
    data-test={`table-dropdown-icon-${expanded ? 1 : 2}`}
    icon='only'
    iconid={`sort-reorder-${expanded ? 4 : 3}f`}
    onClick={(event) => {
      event?.preventDefault();
      onExpand(record, event as React.MouseEvent<HTMLElement>);
    }}
    size='large'
    squared
    type='white'
  />;
};
