import {Spinner} from '../../components';
import {FirmItem} from './FirmItem';
import style from './firms.module.scss';
import userApi from 'api/user';
import {Alert} from 'api/utils';
import classNames from 'classnames';
import {Button} from 'components/InteractiveUIControls/Button/Button';
import {FiltersContext} from 'contexts';
import {useDocumentTitle} from 'hooks';
import {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  fetchOrganizations,
} from 'store/slices';
import {setActiveFilters} from 'store/slices/filtersSlice';

export const Firms = () => {
  const dispatch = useDispatch();
  const {organizations} = useSelector((state) => state);
  const navigate = useNavigate();
  const {setFirmId,
    setFundId} = useContext(FiltersContext);
  const {firmId} = useContext(FiltersContext);
  const {
    organizations: organizationsArray,
    organizationsPending,
  } = organizations;

  const goToFiles = useCallback((orgId) => {
    const selectedOrg = organizationsArray.find((org) => org.organizationId === orgId);

    if (!selectedOrg) {
      Alert('Something went wrong. Contact your administrator.');
      return;
    }

    const fundIds = selectedOrg.funds.map((fund) => fund.fundId);

    const accountIds = selectedOrg.funds.reduce((ids, fund) => {
      for (const acc of fund.accounts) {
        ids.push(acc.accountId);
      }

      return ids;
    }, []);

    dispatch(setActiveFilters({
      accountIds,
      fundIds,
      organizationId: orgId,
    }));

    setFirmId(orgId);
    setFundId('');
    navigate('/firm/documents');
  }, [
    dispatch,
    navigate,
    organizationsArray,
    setFirmId,
    setFundId,
  ]);

  useEffect(() => {
    localStorage.removeItem('_loginAs');
  }, []);

  useEffect(() => {
    if (organizationsArray?.length === 1) {
      goToFiles(organizationsArray[0].organizationId);
    }

    if (organizationsArray?.length && firmId) {
      goToFiles(firmId);
    }
  }, [
    dispatch,
    goToFiles,
    organizationsArray.length,
  ]);

  useEffect(() => {
    if (!organizationsArray.length) {
      dispatch(fetchOrganizations());
      dispatch(userApi.getFirms());
    }
  }, []);

  useDocumentTitle('Organizations');

  const onSelectFirm = (id) => () => {
    goToFiles(id);
  };

  if (organizationsPending || !organizationsArray) {
    return <div className='flex min-h-[45rem] items-center'><Spinner /></div>;
  }

  if (organizationsArray?.length === 0) {
    return <div className={classNames(style.container, '!bg-white-999')}>
      <div className='flex size-full flex-col items-center justify-center gap-0.5'>
        <span className='text-font-3 text-black-700'>INVESTOR PORTAL</span>
        <span className='text-title-1 font-semibold'>Your investor account has been disabled.</span>
        <span className='text-font-1'>
          If you believe there is an error, please <a className='text-main-700 underline' href='mailto:helpdesk@servc.co.il?cc=fms@servc.co.il'> contact support.</a>
        </span>
        <div className='absolute right-4.5 top-2.5'>
          <Button icon='left' iconid='logout-1f' onClick={() => navigate('/auth/login')} text='Logout' type='grey' />
        </div>
      </div>
    </div>;
  }

  return <div className={style.container}>
    <div className={style.block}>
      <div className={style.textContainer}>
        <h1 className={style.investorPortalTitle}>INVESTOR PORTAL</h1>
        <h1 className={style.selectOrgTitle}>Select organization</h1>
      </div>
      <div className={style.firmsContainer}>
        {
          organizationsArray.map((org) => <FirmItem
            id={org.organizationId}
            key={org.organizationId}
            logo={org.organizationLogo}
            name={org.organizationName}
            onSelect={onSelectFirm(org.organizationId)}
          />)
        }
      </div>
    </div>
  </div>;
};
