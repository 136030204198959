import { type ChartData,
  type ChartDataWithPcnt } from '../ChartTypes';
import { CHART_COLORS,
  circularColor } from '../ChartVariables';
import { formatWithAbbreviation } from 'components/Pipes/numberPipes';
import { limitString } from 'components/Pipes/textPipes';

type LegendProps = {
  currency: string,
  decimals: number,
  isInPercents: boolean,
};

type HorBarChartProps = {
  colorList?: string[],
  legendProps?: LegendProps,
  lowerBar: ChartData[],
  upperBar: ChartData[],
};

const renderLegend = (
  allBars: ChartDataWithPcnt[],
  colorList: string[],
  legend: LegendProps,
) => {
  return (
    <div className='flex flex-wrap content-start items-start gap-0.5'>
      {allBars.map((bar, index) =>
        <div className='grey-data-wrapper relative h-1.5 cursor-pointer items-center gap-x-0.25' key={bar.legendName}>
          <div
            className='size-0.5 rounded-[100%]'
            style={{
              background: colorList[index],
            }}
          />
          <span className='text-font-3'>{limitString(bar.legendName, 22)}</span>
          <span className='text-font-3 font-bold'>
            {legend.isInPercents
              ? `${bar.pcnt.toFixed(1)}%`
              : `${legend.currency}${formatWithAbbreviation(
                Math.abs(bar.value),
                legend.decimals,
              )}`}
          </span>
          <div className='legend-tooltip'>
            <div className='tooltip-body'>
              {legend.isInPercents
                ? `${legend.currency}${formatWithAbbreviation(
                  Math.abs(bar.value),
                  legend.decimals,
                )}`
                : `${bar.pcnt.toFixed(1)}%`}
            </div>
            <div className='tooltip-tail' />
          </div>
        </div>,
      )}
    </div>
  );
};

export const HorBarChart: React.FC<HorBarChartProps> =
  ({
    upperBar = [],
    lowerBar = [],
    colorList = CHART_COLORS.default,
    legendProps,
  }: HorBarChartProps) => {
    const upperSum = upperBar.reduce(
      (partialSum, a) => partialSum + Math.abs(a.value),
      0,
    );
    const lowerSum = lowerBar.reduce(
      (partialSum, a) => partialSum + Math.abs(a.value),
      0,
    );
    const maxSum = upperSum > lowerSum ? upperSum : lowerSum;
    const upperPercents = upperBar.map((data) => Math.abs(100 * data.value) / maxSum);
    const lowerPercents = lowerBar.map((data) => Math.abs(100 * data.value) / maxSum);
    const allBarsLen = upperBar.length + lowerBar.length;
    const allBars: ChartDataWithPcnt[] = [];
    const bgList: string[] = [];
    for (const [
      index,
      bar,
    ] of [
        ...upperBar,
        ...lowerBar,
      ].entries()) {
      bgList.push(circularColor(index, allBarsLen, colorList));
      allBars.push({...bar,
        pcnt: 100 * Math.abs(bar.value) / maxSum});
    }

    const legend = legendProps || { currency: '',
      decimals: 2,
      isInPercents: false};
    return (
      <div className='flex w-full flex-col gap-1'>
        {renderLegend(allBars, bgList, legend)}
        <div className='flex w-full flex-col gap-0.25'>
          <div className='flex w-full justify-start'>
            {upperPercents.map((pcnt, index) =>
              <div
                className='h-0.75'
                key={pcnt} style={{
                  background: bgList[index],
                  width: `${pcnt}%`,
                }}
              />,
            )}
          </div>
          <div className='flex w-full justify-start'>
            {lowerPercents.map((pcnt, index) =>
              <div
                className='h-0.75'
                key={pcnt} style={{
                  background: bgList[upperPercents.length + index],
                  width: `${pcnt}%`,
                }}
              />,
            )}
          </div>
        </div>
      </div>

    );
  };
