// eslint-disable-next-line canonical/filename-match-exported
const VARIABLES = {
  booleanButtonActiveColor: 'rgba(251, 153, 47, 0.12)',
  booleanButtonContentActiveColor: 'rgba(251, 153, 47, 1)',
  booleanButtonContentInactiveAltColor: 'rgba(255, 255, 255, 1)',
  booleanButtonContentInactiveColor: '#0F6CBD',
  booleanButtonInactiveAltColor: 'rgba(255, 255, 255, 0.2)',
  booleanButtonInactiveColor: '#FFFFFF',
  borderColor: '#cccfd0',
  borderColorActive: '#10004e',
  darkBlue: '#10004e',
  errorColor: '#ee4d5b',
  gray: '#dee1e2',
  groupTheme: 'rgba(244, 244, 244, 1)',
  mobileWidth: 640,
  sidebarActive: 'rgba(50, 83, 97, 1)',
  sidebarHover: 'rgba(9.6, 11.52, 12.48, 0.08)',
  sidebarTheme: 'rgba(242,243,243,1)',
  successGreen: '#8cce72',
};
export default VARIABLES;
