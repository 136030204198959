// eslint-disable-next-line import/no-unassigned-import
import 'react-phone-input-2/lib/style.css';
import style from './LoginPhoneInput.module.scss';
import PhoneInput from 'react-phone-input-2';

type Props = {
  // for e2e tests, so element can be find easily
  className?: string,
  name?: string,
  onChange?: (string_: string) => void,
  value?: string,
};

export const LoginPhoneInput = ({
  className = '',
  value = '',
  onChange = () => {},
  name = '',
}: Props) => <div>
  <PhoneInput
    buttonClass={style.button}
    containerClass={[
      style.loginPhoneInput,
      className,
    ].join('')}
    country='us'
    data-test={name}
    dropdownClass={style.dropdown}
    enableAreaCodes
    enableLongNumbers
    inputClass={style.input}
    inputProps={{'data-test': name}}
    onChange={(text) => onChange(text)}
    placeholder='Your phone number'
    value={value}
  />
</div>;
