import {
  type ComputedCapitalAccountFund,
  type ComputedCapitalAccountInfoAccount,
  type FixedColumns,
  SecondTableColumnKeys,
} from '../../../../types';
import {
  getBlankColumn,
  Table,
} from '../../../Table';
import { CapitalAccountsDataRenderer } from './CapitalAccountsDataRenderer';
import style from './CapitalAccountsFundTable.module.scss';
import { CapitalAccountsFundTableSubtotalRow } from './CapitalAccountsFundTableSubtotalRow';
import { CapitalAccountsPeriodSelector } from './CapitalAccountsPeriodSelector';
import {
  type ColumnsType,
  type ColumnType,
} from 'antd/lib/table';
import { type FC } from 'react';

const keysAsMandatoryColumnKeys = SecondTableColumnKeys
  .map((value) => value as keyof FixedColumns);

export type CapitalAccountsFundTableProps = {
  dropdownCloseSignal?: boolean,
  record: ComputedCapitalAccountFund,
  selectedAccountPeriodMap: Record<string, string>,
  withoutSubtotalSummary?: boolean,
};

export const CapitalAccountsFundTable: FC<CapitalAccountsFundTableProps> = ({
  dropdownCloseSignal,
  record,
  selectedAccountPeriodMap,
  withoutSubtotalSummary = false,
}) => {
  const columns: ColumnsType<ComputedCapitalAccountInfoAccount> = [
    getBlankColumn({
      width: '3rem',
    }, false),
    {
      className: 'ant-table-cell',
      dataIndex: 'legalName',
      key: 'legalName',
      onCell: (_: unknown, index?: number) => ({
        style: {
          position: 'relative',
          zIndex: index ? record.accounts.length - index : record.accounts.length,
        },
      }),
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsPeriodSelector
        _id={rc._id}
        closeSignal={dropdownCloseSignal}
        fundId={record._id}
        legalName={rc.legalName}
        periodList={Object.keys(rc.periods)}
      />,
      title: 'Fund',
      width: '14.375rem',
    },
    {
      dataIndex: 'commitment',
      key: 'commitment',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey='commitment'
      />,
      title: 'commitment',
      width: '4.875rem',
    },
    getBlankColumn({
      width: '1rem',
    }),
    ...keysAsMandatoryColumnKeys
      .map<ColumnType<ComputedCapitalAccountInfoAccount>>((key) => ({
      dataIndex: key,
      key,
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey={key}
      />,
      title: key,
      width: key === 'contribution' ? '5rem' : '4.875rem',
    })),
    getBlankColumn({
      width: '1rem',
    }),
    {
      dataIndex: 'other',
      key: 'other',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey='other'
      />,
      title: 'other',
      width: '4.875rem',
    },
    getBlankColumn({
      width: '1rem',
    }),
    {
      dataIndex: 'nav',
      key: 'nav',
      render: (
        _: string, rc: ComputedCapitalAccountInfoAccount,
      ) => <CapitalAccountsDataRenderer
        accountId={rc._id}
        fundId={record._id}
        periods={rc.periods}
        valueKey='nav'
      />,
      title: 'nav',
      width: '4.875rem',
    },
  ];

  const getSubtotalRowComponent = () => <CapitalAccountsFundTableSubtotalRow
    data={record.accounts}
    fundId={record._id}
    fundName={record.legalName}
    selectedAccountPeriodMap={selectedAccountPeriodMap}
    withoutSubtotalSummary={withoutSubtotalSummary}
  />;

  return <Table
    className={style.table}
    columns={columns}
    dataSource={record.accounts}
    pagination={false}
    rowKey='_id'
    showHeader={false}
    summary={getSubtotalRowComponent}
  />;
};
