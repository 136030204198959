import './Document.scss';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { limitString } from 'components/Pipes/textPipes';
import { Svgicon } from 'components/Svgicon/Svgicon';
import {
  type FC,
  type SyntheticEvent,
} from 'react';
import { isMobile } from 'services/mobile';
import {
  type DocumentDTO,
  type IndividualFile,
} from 'types';

type DocumentComponentProps = DocumentDTO & {
  documentOnClick:
  (event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLElement>)
  => Promise<void>,
  downloadClick:
  (event: SyntheticEvent | undefined) => Promise<IndividualFile>,
  fileByIdLoading: boolean,
  groupName: string,
  period: number | string,
};

export const RecentDocument: FC<DocumentComponentProps> = ({
  accountName,
  fundName,
  userViewed,
  version,
  period,
  downloadClick,
  fileByIdLoading,
  documentOnClick,
  fileType,
  groupName,
}) => {
  const mobile = isMobile();

  const mainFileContent = `${groupName} ${version ? `#${version}` : ''}`;

  const recentDocumentLogoSide = userViewed ?
    <div className='hide-on-hover'>
      <Svgicon id='seen-recent-document' text={fileType.slice(0, 3)} />
    </div>
    :
    <div className='hide-on-hover relative'>
      <Svgicon id='new-recent-document' text={fileType.slice(0, 3)} />
    </div>;

  const buttonsSide = () =>
    <div className='show-on-hover'>
      <Button
        className='max-h-6 !w-6 !rounded-DEFAULT'
        data-test='download-file'
        icon='only'
        iconid='download-arrow'
        isLoading={fileByIdLoading}
        onClick={(event) => downloadClick(event)}
        size='medium'
        squared
        tooltipWrapperClass='!w-auto'
      />
    </div>;

  return (
    <div
      className='recent-documents-container relative flex h-auto w-full min-w-[18.1875rem] max-w-[90rem] rounded-main bg-white-999 p-[1rem] pl-[0.5rem] shadow-doc'
      data-test={`resent-document-${period}`}
      onClick={documentOnClick}
      role='button'
      tabIndex={0}
    >
      {!mobile && buttonsSide()}
      <div className='recent-document-content flex flex-row items-start gap-[0.5rem]'>
        {recentDocumentLogoSide}
        <div className='flex flex-col gap-[0.375rem]'>
          <div className={'flex flex-row items-center gap-[0.125rem]'}>
            <p className={`${userViewed && 'font-normal'} mb-0 text-font-2 font-bold text-black-999`}>
              {period}
            </p>
            <p className='text-gray-900 mb-0 text-right text-font-3 font-normal leading-4 tracking-normal text-black-999'>•</p>
            <p className={`${userViewed && 'font-normal'} mb-0 text-font-2 font-bold text-black-999`}>
              {mainFileContent.length < 22 ?
                mainFileContent : limitString(mainFileContent, 19)}
            </p>
          </div>
          <div className='flex flex-col gap-[0.25rem]'>
            <div className='flex gap-[0.25rem]'>
              <Svgicon className='size-[0.75rem]' id='luggage-04' />
              <div className='max-w-52'>
                <p className='acc-or-fund-name m-0 truncate text-font-4 font-normal text-black-900'>
                  {fundName}
                </p>
              </div>

            </div>
            <div className='flex gap-[0.25rem]'>
              <Svgicon className='size-[0.75rem]' id='user-profile-03' />
              <div className='max-w-52'>
                <p className='acc-or-fund-name m-0 truncate text-font-4 font-normal text-black-900'>
                  {accountName}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
