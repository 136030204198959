import { type SelectorProps } from '../YearSelector';
import { MultiSelector } from 'components/MultiSelector';
import { type FC,
  useEffect,
  useState} from 'react';
import { useDispatch,
  useSelector } from 'react-redux';
import { filterDocumentsDataByFunds,
  type OrganizationsStoreState } from 'store/slices';
import { type NestedFund } from 'types';

const getOptions = (funds: NestedFund[]) => {
  const fundOptions = funds.map((fund) => {
    return {display: fund.fundName,
      id: fund.fundId,
    };
  });

  return fundOptions;
};

/**
 * Renders a Dropdown component which is the encapsulates the selection of funds
 */
const FundSelector: FC<SelectorProps> = ({style}: SelectorProps) => {
  const dispatch = useDispatch();
  const {
    organizations,
    selectedDocumentsData,
    displayedOrganization } = useSelector<{
    organizations: OrganizationsStoreState,
  }>((state) => state.organizations) as OrganizationsStoreState;

  const currentOrganization = organizations
    ?.find((org) => org.organizationId === displayedOrganization?.organizationId);

  const [
    selectedFundIds,
    setSelectedFundIds,
  ] = useState(currentOrganization?.funds.map((fund) => fund.fundId) || []);

  useEffect(() => {
    if (selectedDocumentsData) {
      setSelectedFundIds(selectedDocumentsData.funds.map((fund) => fund.fundId));
    }
  }, [
    selectedDocumentsData,
  ]);

  const checkFund = (fundIds: string[]) => {
    dispatch(filterDocumentsDataByFunds(fundIds));
    setSelectedFundIds(fundIds);
  };

  return (
    <MultiSelector
      dataSelectionValue='download-files-select-filters-fund-dropdown'
      disabled={!getOptions(currentOrganization?.funds as NestedFund[]).length}
      onOption={checkFund}
      optionList={getOptions(currentOrganization?.funds as NestedFund[])}
      placeholder='funds'
      selectedIds={selectedFundIds}
      style={style || { width: '250px' }}
      title='Fund'
    />
  );
};

export { FundSelector };
