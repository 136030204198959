import { Alert } from '../../api/utils';
import performanceReportImage from '../../assets/images/performance-report-modal-dashboard.png';
import { useResize } from '../../hooks';
import { MAX_ALLOWABLE_MODULE_WIDTH_FOR_REPORT_IMG } from '../../resources/constants';
import { type DocumentsStoreState } from '../../store/slices';
import backgroundImage from './reports-bg.png';
import { ReportSelector } from './ReportSelectors';
import style from './ReportsModule.module.scss';
import { type CSSProperties } from 'react';
import {
  useEffect,
  useMemo,
  useRef} from 'react';
import { useSelector } from 'react-redux';

export const ReportsModule = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {width} = useResize(containerRef);
  const {
    reports,
    reportsError,
    reportsPending,
  } = useSelector<{documents: DocumentsStoreState, }>(
    (state) => state.documents,
  ) as DocumentsStoreState;
  const containerStyle = useMemo(() => {
    const newContainerStyle: CSSProperties = {};
    if (width > MAX_ALLOWABLE_MODULE_WIDTH_FOR_REPORT_IMG) {
      newContainerStyle.backgroundImage
      = `url(${performanceReportImage}), url(${backgroundImage})`;
      newContainerStyle.backgroundSize = 'contain, cover';
    } else {
      newContainerStyle.backgroundImage = `url(${backgroundImage})`;
      newContainerStyle.backgroundSize = 'cover';
    }

    if (reportsPending || reports && reports.length === 0) {
      newContainerStyle.display = 'none';
    }

    return newContainerStyle;
  }, [
    width,
    reports,
    reportsPending,
  ]);

  useEffect(() => {
    if (reportsError) {
      Alert(reportsError);
    }
  }, [
    reportsError,
  ]);

  return (
    <div
      className={style.reportsModule}
      ref={containerRef}
      style={containerStyle}
    >
      <div className={style.reportsLeft}>
        <h3 className={style.reportsTitle}>
          Digital Performance Reports
        </h3>
        {
          reports && <div className='flex flex-col gap-0.375 dt:flex-row'>
            <ReportSelector reports={reports} />
          </div>
        }
      </div>
    </div>
  );
};
