import style from './DocumentGroupName.module.scss';
import { documentsApi } from 'api/documentsApi';
import ClosedFolder from 'assets/icons/documents/closed-folder.svg?react';
import OpenedFolder from 'assets/icons/documents/opened-folder.svg?react';
import { Button } from 'components/InteractiveUIControls/Button/Button';
import { Tooltip } from 'components/InteractiveUIControls/Tooltip/Tooltip';
import { limitString } from 'components/Pipes/textPipes';
import { type FC } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'services/mobile';
import { markDocumentsAsSeen } from 'store/slices';
import { type DocumentDTO } from 'types';

type DocumentGroupNameProps = {
  categoryId: string,
  documentGroupId: string,
  documentGroupName: string,
  documents: DocumentDTO[],
  isOpen: boolean,
  numberOfDocuments: number,
  numberOfNewDocuments: number,
};

export const DocumentGroupName: FC<DocumentGroupNameProps> = ({
  documentGroupName,
  documentGroupId,
  documents,
  categoryId,
  isOpen,
  numberOfDocuments,
  numberOfNewDocuments,
}) => {
  const dispatch = useDispatch();
  const markAsSeenClick = async (
    event: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
  ) => {
    event?.stopPropagation();
    const filteredNewDocs = documents.filter((doc) => doc.userViewed === false);
    const documentIds = filteredNewDocs.map((doc) => doc._id);
    if (documentIds) {
      await documentsApi.viewDocuments({documentIds});
    }

    dispatch(markDocumentsAsSeen({
      categoryId,
      documentGroupId,
      documentIds,
    }));
  };

  return (
    <div
      className={style.nameContainer}
      style={isOpen ? {
        borderBottom: '2px solid rgba(221, 222, 223, 0.3)',
        borderRadius: '0.5rem 0.5rem 0 0',
      } : {}}
    >
      <div className={style.nameLeft}>
        {
          isOpen ?
            <OpenedFolder /> :
            <ClosedFolder />
        }
        <div className={style.documentGroupDetails}>
          <h3 className={style.documentGroupName}>
            {isMobile() ? limitString(documentGroupName, 24) : documentGroupName}
          </h3>
          <h3 className={style.documentTotalDocumentNumber}>
            {`${numberOfDocuments} documents`}
          </h3>
        </div>
      </div>
      {
        Boolean(numberOfNewDocuments) && numberOfNewDocuments > 0 &&
        <div className={style.nameRight}>
          {isOpen && !isMobile() && <Button
            className={style.fileViewButton}
            data-test='mark-file-as-seen'
            icon='left'
            iconid='mark-seen'
            onClick={(event) => markAsSeenClick(event)}
            size='medium'
            type='grey'
          >Mark as seen </Button>}
          <div className='tooltip-wrapper black w-fit'>
            <Tooltip>{`${numberOfNewDocuments} new document${numberOfNewDocuments === 1 ? '' : 's'}`}</Tooltip>
            <div className={style.numDocumentsContainer}>

              <p className={style.numberOfDocuments}>
                {numberOfNewDocuments}
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
