import {type CapitalAccountsFilter } from '../../../api/capitalAccountsApi';
import { capitalAccountsApi } from '../../../api/capitalAccountsApi';
import { Alert } from '../../../api/utils';
import {type CapitalAccountFund,
  type ComputedCapitalAccountFund,
  type SelectedFundAccountPeriodMap,
  type TableColumnDisplayValues } from '../../../types';
import { calculateColumnsForFund } from './helpers';
import {type PayloadAction } from '@reduxjs/toolkit';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

export type CapitalAccountsStoreState = {
  capitalAccountsData: CapitalAccountFund[] | null,
  capitalAccountsDataError: string | null,
  capitalAccountsDataPending: boolean,
  computedCapitalAccountsData: ComputedCapitalAccountFund[] | null,
  hasCapitalAccounts: boolean,
  hasCapitalAccountsError: string | null,
  hasCapitalAccountsPending: boolean,
  selectedFundAccountPeriodMap: SelectedFundAccountPeriodMap,
  summaryStatistics: TableColumnDisplayValues | null,
  summaryStatisticsByFund: Record<string, TableColumnDisplayValues>,
};

const initialState: CapitalAccountsStoreState = {
  capitalAccountsData: null,
  capitalAccountsDataError: null,
  capitalAccountsDataPending: false,
  computedCapitalAccountsData: null,
  hasCapitalAccounts: false,
  hasCapitalAccountsError: null,
  hasCapitalAccountsPending: false,
  selectedFundAccountPeriodMap: {},
  summaryStatistics: null,
  summaryStatisticsByFund: {},
};

export const fetchCapitalAccountsPreview = createAsyncThunk(
  'capitalAccounts/preview',
  async (filter: CapitalAccountsFilter) => {
    const { hasAnyCAReports } = await capitalAccountsApi.checkForCapitalAccounts(filter);

    return hasAnyCAReports;
  },
);

export const fetchCapitalAccounts = createAsyncThunk(
  'capitalAccounts/data',
  async (filter: CapitalAccountsFilter) => {
    const capitalAccounts = await capitalAccountsApi.getCapitalAccounts(filter);

    return capitalAccounts;
  },
);

const capitalAccountsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchCapitalAccountsPreview.pending, (state) => {
        state.hasCapitalAccountsPending = true;
      })
      .addCase(fetchCapitalAccountsPreview.fulfilled, (state, action) => {
        state.hasCapitalAccountsPending = false;
        state.hasCapitalAccounts = action.payload;
      })
      .addCase(fetchCapitalAccountsPreview.rejected, (state) => {
        state.hasCapitalAccountsPending = false;
        state.hasCapitalAccountsError = 'Could not fetch capital accounts preview';
        Alert(state.hasCapitalAccountsError);
      })
      .addCase(fetchCapitalAccounts.pending, (state) => {
        state.capitalAccountsDataPending = true;
      })
      .addCase(fetchCapitalAccounts.fulfilled, (state, action) => {
        state.capitalAccountsDataPending = false;
        state.capitalAccountsData = action.payload.data;
        state.computedCapitalAccountsData = action.payload.data.map(
          calculateColumnsForFund,
        );

        state.selectedFundAccountPeriodMap = action.payload.data.reduce((acc, fund) => {
          const {
            _id: fundId,
            accounts,
          } = fund;

          const reducedAccounts = accounts.reduce((accountAcc, account) => {
            const {
              _id: accountId,
              periods,
            } = account;
            const firstPeriod = Object.keys(periods)[0];
            accountAcc[accountId] = firstPeriod;
            return accountAcc;
          }, {} as Record<string, string>);

          acc[fundId] = reducedAccounts;
          return acc;
        }, {} as SelectedFundAccountPeriodMap);

        state.summaryStatistics = null;
        state.summaryStatisticsByFund = {};
      })
      .addCase(fetchCapitalAccounts.rejected, (state) => {
        state.capitalAccountsDataPending = false;
        state.capitalAccountsDataError = 'Could not fetch capital accounts';
      });
  },
  initialState,
  name: 'capitalAccounts',
  reducers: {
    selectPeriodForAccount: (
      state,
      action: PayloadAction<{
        accountId: string,
        fundId: string,
        period: string,
      }>,
    ) => {
      const {
        accountId,
        fundId,
        period,
      } = action.payload;

      const fundAccPairList = Object.entries(state.selectedFundAccountPeriodMap);

      const mappedFundAccPairs = fundAccPairList.map(([
        oldFundId,
        oldAccountList,
      ]) => {
        if (oldFundId !== fundId) {
          return [
            oldFundId,
            oldAccountList,
          ];
        }

        const accountKeys = Object.keys(oldAccountList);

        if (!accountKeys.includes(accountId)) {
          return [
            oldFundId,
            oldAccountList,
          ];
        }

        oldAccountList[accountId] = period;

        return [
          fundId,
          {
            ...oldAccountList,
          },
        ];
      });

      state.selectedFundAccountPeriodMap = Object.fromEntries(mappedFundAccPairs);
    },
    setSummaryStatistics: (
      state,
      action: PayloadAction<{
        total: TableColumnDisplayValues,
      }>,
    ) => {
      state.summaryStatistics = action.payload.total;
    },
    setSummaryStatisticsByFund: (
      state,
      action: PayloadAction<{
        fundId: string,
        subtotal: TableColumnDisplayValues,
      }>,
    ) => {
      state.summaryStatisticsByFund[action.payload.fundId] = action.payload.subtotal;
    },
  },
});

const capitalAccountsReducer = capitalAccountsSlice.reducer;

export const {
  selectPeriodForAccount,
  setSummaryStatistics,
  setSummaryStatisticsByFund,
} = capitalAccountsSlice.actions;

export {capitalAccountsReducer};
