import { useRelativePathsToBasePath } from '../../hooks';
import {
  type Filters,
  type FilterStoreState,
  type OrganizationsStoreState,
  resetDisplayedOrganizations,
  selectOrganization,
} from '../../store/slices';
import { fetchOrganizations } from '../../store/slices';
import { FileViewModal } from './FileViewModal/FileViewModal';
import style from './firm.module.scss';
import {
  ROUTE_ARRAY,
} from './routes';
import userApi from 'api/user';
import {
  DownloadFilesModal,
  Filters as FiltersComponent,
  PrivacyAndTerms,
  RouteMatcher,
  Spinner,
  Support,
} from 'components';
import {
  GlobalElementBusContext,
  GlobalElementBusContextProvider,
  ModalActivationContext,
  ModalActivationProvider,
} from 'contexts';
import { Error } from 'pages/Error';
import {
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { type AppDispatch } from 'store';

const VALID_PATHS_FOR_CA_BG = [
  '/firm/capital-accounts/*',
];

const Firm = () => {
  const { valid } = useRelativePathsToBasePath(...VALID_PATHS_FOR_CA_BG);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const { fileViewId } = Object.fromEntries(queryParameters.entries());

  const {
    showDownloadsModal,
    showSupportModal,
    fileViewModal,
    toggleFileViewModal,
  } = useContext(ModalActivationContext);
  const containerRef = useRef(null);
  const {
    addRef,
  } = useContext(GlobalElementBusContext);
  const isLoading = useSelector<
  { global: { loading: boolean, }, }>(
    (state) => state.global.loading,
  ) as boolean;
  const {
    organizations,
    displayedOrganization,
  } = useSelector<{ organizations: OrganizationsStoreState, }>(
    (state) => state.organizations,
  ) as OrganizationsStoreState;

  const activeFilters = useSelector<{
    filters: FilterStoreState,
  }>((state) => state.filters.activeFilters) as Filters | null;

  useEffect(() => {
    localStorage.removeItem('_loginAs');
  }, []);

  useEffect(() => {
    if (fileViewId) {
      toggleFileViewModal(true, fileViewId);
    }
  }, [
    fileViewId,
    toggleFileViewModal,
  ]);

  useEffect(() => {
    if (!activeFilters) {
      navigate('/firms');
      return;
    }

    if (!organizations?.length) {
      dispatch(fetchOrganizations());
      dispatch(userApi.getFirms());
    }

    if (displayedOrganization) {
      dispatch(userApi.getFirm(displayedOrganization.organizationId));
    } else {
      dispatch(selectOrganization(activeFilters.organizationId));
      dispatch(resetDisplayedOrganizations(activeFilters as Filters));
    }
  }, [
    dispatch,
    displayedOrganization,
    activeFilters,
    navigate,
    organizations?.length,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      addRef(containerRef);
    }
  }, [
    addRef,
    containerRef,
  ]);

  return (
    <>
      {isLoading ? <Spinner />
        : <div className={`
          ${style.firmContainer} ${valid ? style.firmContainerWithBg : ''}`}
        >
          <div className={`
            ${valid ? `${style.fixedGradient} ` : ''} flex h-full flex-col items-center gap-[1rem]`}
          >
            <FiltersComponent />
            <RouteMatcher
              fallbackElement={Error}
              subRoutes={ROUTE_ARRAY}
            />
            {showSupportModal && <Support />}
            {showDownloadsModal && <DownloadFilesModal />}
            {fileViewModal.show &&
              fileViewModal.id && <FileViewModal docId={fileViewModal.id} />}
          </div>
          <PrivacyAndTerms />
        </div>
      }
    </>
  );
};

export const FirmWithModalContexts = () => <ModalActivationProvider>
  <GlobalElementBusContextProvider>
    <Firm />
  </GlobalElementBusContextProvider>
</ModalActivationProvider>;
