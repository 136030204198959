import {reportsActionTypes} from 'store/actions';

const initialState = {
  hasReports: false,
  reports: [],
  reportsLoading: false,
};

// eslint-disable-next-line default-param-last
export const reports = (state = initialState, {type,
  payload}) => {
  switch (type) {
  case reportsActionTypes.GET_FIRM_REPORTS_SUCCESS:
    return {...state,
      reports: payload};
  case reportsActionTypes.REPORTS_LOADING:
    return {...state,
      reportsLoading: payload};
  case reportsActionTypes.HAS_REPORTS:
    return {...state,
      hasReports: payload};
  case reportsActionTypes.RESET:
    return {...initialState};
  default:
    return state;
  }
};
