import {
  type CapitalAccountsStoreState,
} from '../../store/slices';
import GoBackButton from '../GoBackButton';
import { Profile } from '../Profile';
import style from './Filters.module.scss';
import classNames from 'classnames';
import { NestedOrganizationsSelector } from 'components/NestedOrganizationsSelector/NestedOrganizationsSelector';
import { useRelativePathsToBasePath } from 'hooks';
import { useSelector } from 'react-redux';
import { useLocation,
  useParams } from 'react-router-dom';
import { isMobile } from 'services/mobile';

const VALID_PATHS_FOR_FILTERS = [
  '/firm/documents/*',
];

const VALID_PATHS_FOR_ACCOUNT_NAME = [
  '/firm/capital-accounts/*',
];

const Filters = () => {
  const params = useParams();
  const mobile = isMobile();
  const location = useLocation();
  const accountId = params['*']?.split('/')[2];
  const fundId = params['*']?.split('/')[1];

  const { valid } = useRelativePathsToBasePath(...VALID_PATHS_FOR_FILTERS);
  const {
    valid: validCapitalAccountsPath,
  } = useRelativePathsToBasePath(...VALID_PATHS_FOR_ACCOUNT_NAME);

  const isAccountRoute = validCapitalAccountsPath && Boolean(accountId);

  const fundName = useSelector<{
    capitalAccounts: CapitalAccountsStoreState,
  }>(
    (state) => state
      .capitalAccounts
      .capitalAccountsData
      ?.find((fund) => fund._id === fundId)?.legalName) as string;

  return (
    <div className={classNames(style.filtersContainer, { '!bg-[#EEDCBF]': validCapitalAccountsPath })}>
      <div className={style.filters}>
        {
          valid
            ? <div className={style.selectorsContainer}>
              <div className={style.subContainer}>
                <NestedOrganizationsSelector />
              </div>
            </div>
            : <div className={style.buttonContainer}>
              <GoBackButton to={isAccountRoute ? '/firm/capital-accounts' : '/firm/documents'} />
              <div className='flex gap-[0.5rem]'>
                {!mobile && !location.pathname.includes('settings') && <><h1
                  className={fundName ? style.orgNameLight : style.orgName}
                >
                  Digital capital account
                </h1>{fundName &&
                  <>
                    <h1 className={style.orgNameLight}>/</h1>
                    <h1 className={style.orgName}>{fundName}</h1>
                  </>}
                </>}

              </div>
            </div>
        }
        <Profile />
      </div>
    </div>
  );
};

export default Filters;
