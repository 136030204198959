/* eslint-disable import/no-unassigned-import */
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { SpecialZoomLevel,
  Viewer,
  Worker } from '@react-pdf-viewer/core';
import {type ToolbarProps,
  type ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import classNames from 'classnames';
import {type CSSProperties,
  type FC,
  type ReactElement,
  useEffect,
  useState} from 'react';
import { isLandscape,
  isMobile,
  isMobileInLandscape} from 'services/mobile';

type PdfReaderProps = {
  style?: CSSProperties,
  title?: string,
  url?: string,
};

const moreActionsSlots = (slots: ToolbarSlot): ToolbarSlot => {
  const filteredSlots = { ...slots };
  filteredSlots.ShowPropertiesMenuItem = () => <div />;
  filteredSlots.OpenMenuItem = () => <div />;
  filteredSlots.DownloadMenuItem = () => <div />;
  return filteredSlots;
};

const getScale = () => {
  return isMobileInLandscape() || !isLandscape() ? SpecialZoomLevel.PageWidth : 1.5;
};

const PdfReader: FC<PdfReaderProps> = ({
  url,
  title,
  style,
}) => {
  const [
    mobile,
    setMobile,
  ] = useState(isMobile());
  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) =>
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
          SwitchTheme,
        } = slots;
        return (
          <div className='flex w-full items-center'>
            {!mobile && <>
              <div className='px-0.125'>
                <ShowSearchPopover />
              </div>
              <div className='px-0.125'>
                <GoToPreviousPage />
              </div>
              <div className='w-4 px-0.125'>
                <CurrentPageInput />
              </div>
              <div className='pl-0.75 pr-0.25'>
                / <NumberOfPages />
              </div>
              <div className='px-0.125'>
                <GoToNextPage />
              </div>
            </>}
            <div className={classNames('px-0.125', { 'ml-auto': !mobile })}>
              <ZoomOut />
            </div>
            {!mobile && <div className='px-0.125'>
              <Zoom />
            </div>}
            <div className='px-0.125'>
              <ZoomIn />
            </div>
            {!mobile && <div className='ml-auto px-0.125'>
              <SwitchTheme />
            </div>}
            {!mobile && <div className='px-0.125'>
              <EnterFullScreen />
            </div>}
            <div className={classNames('px-0.125', { 'ml-auto': mobile })}>
              <Download />
            </div>
            {!mobile && <div className='px-0.125'>
              <Print />
            </div>}
            <div className='px-0.125'>
              <MoreActionsPopover toolbarSlot={moreActionsSlots(slots)} />
            </div>
          </div>
        );
      }}
    </Toolbar>;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => mobile ? [] : defaultTabs,
  });

  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  useEffect(() => {
    const handleResize = () => {
      setMobile(isMobile());
      zoomTo(getScale());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [
    zoomTo,
  ]);

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js' >
      <div style={style} title={title}>
        <Viewer
          defaultScale={getScale()}
          fileUrl={url || ''}
          plugins={[
            defaultLayoutPluginInstance,
            zoomPluginInstance,
          ]}
        />
      </div>
    </Worker>
  );
};

export {PdfReader};
