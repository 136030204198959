const environment = {
  apiUrl: import.meta.env.VITE_APP_API_URL,
  env: import.meta.env.VITE_APP_ENVIRONMENT || 'test',
  fmsLoginUrl: import.meta.env.VITE_APP_FMS_URL,
  resendVerificationTimeout:
    import.meta.env.VITE_APP_VERIFICATION_TIMEOUT || 15,
};

export default environment;

